import React from 'react';

const DocNormal = ({ fill }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
			<path
				opacity='0.4'
				d='M16.24 4.46094H7.76004C5.29004 4.46094 3.29004 6.47094 3.29004 8.93094V18.3409C3.29004 20.8009 5.30004 22.8109 7.76004 22.8109H16.23C18.7 22.8109 20.7 20.8009 20.7 18.3409V8.93094C20.71 6.46094 18.7 4.46094 16.24 4.46094Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M14.35 2.81055H9.65001C8.61001 2.81055 7.76001 3.65055 7.76001 4.69055V5.63055C7.76001 6.67055 8.60001 7.51055 9.64001 7.51055H14.35C15.39 7.51055 16.23 6.67055 16.23 5.63055V4.69055C16.24 3.65055 15.39 2.81055 14.35 2.81055Z'
				fill={fill ? fill : '#5B6B79'}
			/>
		</svg>
	);
};

export default DocNormal;
