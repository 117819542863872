import React from 'react';

const Message = ({ fill }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
			<path
				d='M20 7.56055C21.5188 7.56055 22.75 6.32933 22.75 4.81055C22.75 3.29176 21.5188 2.06055 20 2.06055C18.4812 2.06055 17.25 3.29176 17.25 4.81055C17.25 6.32933 18.4812 7.56055 20 7.56055Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				opacity='0.4'
				d='M20 8.81055C17.79 8.81055 16 7.02055 16 4.81055C16 4.08055 16.21 3.40055 16.56 2.81055H7C4.24 2.81055 2 5.04055 2 7.79055V13.7705V14.7705C2 17.5205 4.24 19.7505 7 19.7505H8.5C8.77 19.7505 9.13 19.9305 9.3 20.1505L10.8 22.1405C11.46 23.0205 12.54 23.0205 13.2 22.1405L14.7 20.1505C14.89 19.9005 15.19 19.7505 15.5 19.7505H17C19.76 19.7505 22 17.5205 22 14.7705V8.25055C21.41 8.60055 20.73 8.81055 20 8.81055Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M12 12.8105C11.44 12.8105 11 12.3605 11 11.8105C11 11.2605 11.45 10.8105 12 10.8105C12.55 10.8105 13 11.2605 13 11.8105C13 12.3605 12.56 12.8105 12 12.8105Z'
				fill={fill ? fill : '#1D2630'}
			/>
			<path
				d='M16 12.8105C15.44 12.8105 15 12.3605 15 11.8105C15 11.2605 15.45 10.8105 16 10.8105C16.55 10.8105 17 11.2605 17 11.8105C17 12.3605 16.56 12.8105 16 12.8105Z'
				fill={fill ? fill : '#1D2630'}
			/>
			<path d='M8 12.8105C7.44 12.8105 7 12.3605 7 11.8105C7 11.2605 7.45 10.8105 8 10.8105C8.55 10.8105 9 11.2605 9 11.8105C9 12.3605 8.56 12.8105 8 12.8105Z' fill='#1D2630' />
		</svg>
	);
};

export default Message;
