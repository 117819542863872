import React from 'react';

const HomeIcon = ({ fill }) => {
	return (
		<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M21.5 11.7105V4.91055C21.5 3.41055 20.86 2.81055 19.27 2.81055H15.23C13.64 2.81055 13 3.41055 13 4.91055V11.7105C13 13.2105 13.64 13.8105 15.23 13.8105H19.27C20.86 13.8105 21.5 13.2105 21.5 11.7105Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M11 13.9105V20.7105C11 22.2105 10.36 22.8105 8.77 22.8105H4.73C3.14 22.8105 2.5 22.2105 2.5 20.7105V13.9105C2.5 12.4105 3.14 11.8105 4.73 11.8105H8.77C10.36 11.8105 11 12.4105 11 13.9105Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				opacity='0.4'
				d='M21.5 20.7105V17.9105C21.5 16.4105 20.86 15.8105 19.27 15.8105H15.23C13.64 15.8105 13 16.4105 13 17.9105V20.7105C13 22.2105 13.64 22.8105 15.23 22.8105H19.27C20.86 22.8105 21.5 22.2105 21.5 20.7105Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				opacity='0.4'
				d='M11 7.71055V4.91055C11 3.41055 10.36 2.81055 8.77 2.81055H4.73C3.14 2.81055 2.5 3.41055 2.5 4.91055V7.71055C2.5 9.21055 3.14 9.81055 4.73 9.81055H8.77C10.36 9.81055 11 9.21055 11 7.71055Z'
				fill={fill ? fill : '#5B6B79'}
			/>
		</svg>
	);
};

export default HomeIcon;
