import React from 'react';

const SecuritySafeIcon = ({ fill }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
			<path
				opacity='0.4'
				d='M20.91 11.9308C20.91 16.8208 17.36 21.4008 12.51 22.7408C12.18 22.8308 11.82 22.8308 11.49 22.7408C6.63996 21.4008 3.08997 16.8208 3.08997 11.9308V7.54086C3.08997 6.72086 3.70998 5.79086 4.47998 5.48086L10.05 3.20086C11.3 2.69086 12.71 2.69086 13.96 3.20086L19.53 5.48086C20.29 5.79086 20.92 6.72086 20.92 7.54086L20.91 11.9308Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M14.5 11.3105C14.5 9.93055 13.38 8.81055 12 8.81055C10.62 8.81055 9.5 9.93055 9.5 11.3105C9.5 12.4305 10.24 13.3605 11.25 13.6805V16.3105C11.25 16.7205 11.59 17.0605 12 17.0605C12.41 17.0605 12.75 16.7205 12.75 16.3105V13.6805C13.76 13.3605 14.5 12.4305 14.5 11.3105Z'
				fill={fill ? fill : '#5B6B79'}
			/>
		</svg>
	);
};

export default SecuritySafeIcon;
