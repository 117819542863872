import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "./Header";
import SideBar from "./SideBar";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState(false);
  // const { user } = useSelector(s => s);
  // useEffect(() => {
  // 	if (!user?.id) getUser();
  // }, []);
  // const getUser = () => {
  // 	Axios()
  // 		.get(`api/v1/auth/me`)
  // 		.then(r => {
  // 			dispatch({ type: 'SET_USER', payload: r?.data?.user ?? {} });
  // 		})
  // 		.catch(e => {})
  // 		.finally(() => {});
  // };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setResponse(true);
      } else {
        setResponse(false);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Add resize listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []);
  return (
    <div className="container2 relative flex w-full">
      <div
        className={`fixed top-0 min-h-screen z-[991] ${
          response ? "w-[65px]" : "w-[317px]"
        } duration-250 transition-all ease-linear`}
      >
        <SideBar setResponse={setResponse} response={response} />
      </div>
      <div
        className={`overflow-hidden ${
          response ? "ml-[65px] " : "ml-[317px] "
        } w-full min-h-screen relative duration-250 transition-all ease-linear`}
      >
        <div className="">
          <Header setResponse={setResponse} response={response} />
        </div>
        <div className=" sm:p-[20px] p-[10px] bg-alabaster !pb-[100px] mt-[73px] min-h-[90vh]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
