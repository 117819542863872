import React from "react";
import { ClipLoader } from "react-spinners";

const ContentSpinner = () => {
  return (
    <div className="flex justify-center items-center min-h-96">
      <ClipLoader />
    </div>
  );
};

export default ContentSpinner;
