import React from 'react';

const DocIcon = ({ fill }) => {
	return (
		<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				opacity='0.4'
				d='M20.5 11.0005H17.61C15.24 11.0005 13.31 9.07055 13.31 6.70055V3.81055C13.31 3.26055 12.86 2.81055 12.31 2.81055H8.07C4.99 2.81055 2.5 4.81055 2.5 8.38055V17.2405C2.5 20.8105 4.99 22.8105 8.07 22.8105H15.93C19.01 22.8105 21.5 20.8105 21.5 17.2405V12.0005C21.5 11.4505 21.05 11.0005 20.5 11.0005Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M15.8001 3.02054C15.3901 2.61054 14.6801 2.89054 14.6801 3.46054V6.95054C14.6801 8.41054 15.9201 9.62054 17.4301 9.62054C18.3801 9.63054 19.7001 9.63054 20.8301 9.63054C21.4001 9.63054 21.7001 8.96054 21.3001 8.56054C19.8601 7.11054 17.2801 4.50054 15.8001 3.02054Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M13.5 14.5605H7.5C7.09 14.5605 6.75 14.2205 6.75 13.8105C6.75 13.4005 7.09 13.0605 7.5 13.0605H13.5C13.91 13.0605 14.25 13.4005 14.25 13.8105C14.25 14.2205 13.91 14.5605 13.5 14.5605Z'
				fill={fill ? fill : '#1D2630'}
			/>
			<path
				d='M11.5 18.5605H7.5C7.09 18.5605 6.75 18.2205 6.75 17.8105C6.75 17.4005 7.09 17.0605 7.5 17.0605H11.5C11.91 17.0605 12.25 17.4005 12.25 17.8105C12.25 18.2205 11.91 18.5605 11.5 18.5605Z'
				fill={fill ? fill : '#1D2630'}
			/>
		</svg>
	);
};

export default DocIcon;
