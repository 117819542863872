import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const resources = {
	en: require('../locate/en.json'),
	ru: require('../locate/ru.json'),
	uz: require('../locate/uz.json'),
};

i18n.use(XHR)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: window.localStorage.getItem('i18nextLng') || 'uz',
		fallbackLng: 'ru',
		debug: true,
		ns: ['translations'],
		defaultNS: 'translations',
		keySeparator: '.',
		interpolation: {
			escapeValue: false,
			formatSeparator: ',',
		},
		react: {
			wait: true,
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed',
			nsMode: 'default',
		},
	});

export default i18n;
