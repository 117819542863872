import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../sections/layoutSections/Layout';
import { roles } from '../utils/roleManager/roles';

export const routes = [
	{
		element: (
			<Layout>
				<Outlet />
			</Layout>
		),
		handle: {
			title: 'Dashboard',
		},
		children: [
			{
				index: true,
				component: lazy(() => import('../components/main_pages/Home')),
				handle: {
					title: 'Home',
					roles: [roles.allow_any],
				},
			},
			{
				path: 'statistics/:child_id',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/Home')),
						handle: {
							title: 'User Info',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'bordercrossing/',
				children: [
					{
						path: 'citizens-abroad',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/bordercrossing_pages/CitizensAbroad')),
								handle: {
									title: 'Граждане за рубежом',
									roles: [roles.allow_any],
								},
							},
							{
								path: 'list',
								children: [
									{
										index: true,
										component: lazy(() => import('../components/bordercrossing_pages/CitizensAbroadList')),
										handle: {
											title: 'Граждане за рубежом',
											roles: [roles.allow_any],
										},
									},
								],
							},
						],
					},

					{
						path: 'citizens-abroaddays',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/bordercrossing_pages/CitizensAbroadDays')),
								handle: {
									title: 'Граждане за рубежом (более 90 дней)',
									roles: [roles.allow_any],
								},
							},
							{
								path: 'list',
								children: [
									{
										index: true,
										component: lazy(() => import('../components/bordercrossing_pages/CitizensAbroadDaysList')),
										handle: {
											title: 'Граждане за рубежом (более 90 дней)',
											roles: [roles.allow_any],
										},
									},
								],
							},
						],
					},
					{
						path: 'returned-citizens',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/bordercrossing_pages/ReturnedCitizens')),
								handle: {
									title: 'Вернувшиеся граждане',
									roles: [roles.allow_any],
								},
							},
							{
								path: 'list',
								children: [
									{
										index: true,
										component: lazy(() => import('../components/bordercrossing_pages/ReturnedCitizensList')),
										handle: {
											title: 'Вернувшиеся граждане',
											roles: [roles.allow_any],
										},
									},
								],
							},
						],
					},
					{
						path: 'citizens-country',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/bordercrossing_pages/ForeignCitizensCountry')),
								handle: {
									title: 'Иностранные граждане в стране',
									roles: [roles.allow_any],
								},
							},
						],
					},
				],
			},
			{
				path: 'migrant/:id',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/UserInfo')),
						handle: {
							title: 'User Info',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'accesses/organizations',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/Accesses')),
						handle: {
							title: 'Accesses',
							roles: [roles.allow_any],
						},
					},
					{
						path: ':id',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/main_pages/AccessesItem')),
								handle: {
									title: 'Accesses item',
									roles: [roles.allow_any],
								},
							},
						],
					},
				],
			},
			{
				path: 'accesses/employees',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/AccessesEmployees')),
						handle: {
							title: 'AccessesEmployees',
							roles: [roles.allow_any],
						},
					},
					{
						path: 'list',
						children: [
							{
								index: true,
								component: lazy(() => import('../components/main_pages/AccessesEmployeesList')),
								handle: {
									title: 'AccessesEmployees list',
									roles: [roles.allow_any],
								},
							},
							{
								path: '/accesses/employees/list/:id',
								component: lazy(() => import('../components/main_pages/AccessesEmployeesListItem')),
								handle: {
									title: 'AccessesEmployees list item',
									roles: [roles.allow_any],
								},
							},
						],
					},
				],
			},
			{
				path: '/immigration/to-come',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/immigragion_pages/ToCome')),
						handle: {
							title: 'Immigration/To-come',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: '/immigration/to-leave',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/immigragion_pages/ToLeave')),
						handle: {
							title: 'Immigration/To-leave',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: '/emigration/to-come',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/emigtation_pages/ToCome')),
						handle: {
							title: 'Emigration/To-comed',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: '/emigration/to-leave',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/emigtation_pages/ToLeave')),
						handle: {
							title: 'Emigration/To-leave',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'test',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/Test')),
						handle: {
							title: 'Test',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'map',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/MapUzb')),
						handle: {
							title: 'Map',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'map2',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/MapUzbb')),
						handle: {
							title: 'Map2',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'mapregion',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/MapUzbRegion')),
						handle: {
							title: 'Map Region',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'map3',
				children: [
					{
						index: true,
						component: lazy(() => import('../components/main_pages/MapUzbDistrict')),
						handle: {
							title: 'Map',
							roles: [roles.allow_any],
						},
					},
				],
			},
		],
	},
	{
		path: 'login',
		component: lazy(() => import('../components/main_pages/auth/Login')),
		handle: {
			title: 'Login',
			roles: [roles.allow_any],
		},
	},
	{
		path: '*',
		component: lazy(() => import('../components/main_pages/NotFount')),
		handle: {
			title: '404 | Not Fount',
			roles: [roles.allow_any],
		},
	},
];
