import React from 'react';

const LoadingMain = () => (
	<div className='fixed top-0 left-0 w-screen h-screen z-[9999999] flex justify-center items-center bg-[rgba(0,0,0,0.3)] backdrop-blur-sm'>
		<div className='h-12 w-12 flex justify-center items-center'>
			<div className='h-8 w-8 m-[6px] relative animate-spin'>
				<div className='absolute top-0 left-0 h-full w-full animate-[spin_2s_infinite_ease-in-out] delay-[-1.1s]'>
					<div className='bg-black rounded-[3px] h-[6px] w-[6px]'></div>
				</div>
				<div className='absolute top-0 left-0 h-full w-full animate-[spin_2s_infinite_ease-in-out] delay-[-1s]'>
					<div className='bg-black rounded-[3px] h-[6px] w-[6px]'></div>
				</div>
				<div className='absolute top-0 left-0 h-full w-full animate-[spin_2s_infinite_ease-in-out] delay-[-0.9s]'>
					<div className='bg-black rounded-[3px] h-[6px] w-[6px]'></div>
				</div>
				<div className='absolute top-0 left-0 h-full w-full animate-[spin_2s_infinite_ease-in-out] delay-[-0.8s]'>
					<div className='bg-black rounded-[3px] h-[6px] w-[6px]'></div>
				</div>
				<div className='absolute top-0 left-0 h-full w-full animate-[spin_2s_infinite_ease-in-out] delay-[-0.7s]'>
					<div className='bg-black rounded-[3px] h-[6px] w-[6px]'></div>
				</div>
				<div className='absolute top-0 left-0 h-full w-full animate-[spin_2s_infinite_ease-in-out] delay-[-0.6s]'>
					<div className='bg-black rounded-[3px] h-[6px] w-[6px]'></div>
				</div>
			</div>
		</div>
		<style>{`
			html, body {
				overflow: hidden !important;
			}
		`}</style>
	</div>
);

export default LoadingMain;
