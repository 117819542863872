import React from "react";
import { Provider } from "react-redux";
import createStore from "./utils/store";
import WebProvider from "./utils/WebProvider";
import RouteProvider from "./routers/RouteProvider";

const App = () => {
  const store = createStore();
  return (
    <Provider store={store}>
      <WebProvider>
        <RouteProvider />
      </WebProvider>
    </Provider>
  );
};
export default App;
