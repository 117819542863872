import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ArrowRight from '../../assets/svg/ArrowRight';
import DocIcon from '../../assets/svg/Doc';
import DocNormal from '../../assets/svg/DocNormal';
import HomeIcon from '../../assets/svg/HomeIcon';
import Message from '../../assets/svg/Message';
import ProfileUserIcon from '../../assets/svg/ProfileUserIcon';
import SecuritySafeIcon from '../../assets/svg/SecuritySafeIcon';
import { removeToken } from '../../utils/tokenStorge';

const SideBar = ({ response = false, setResponse }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openSection, setOpenSection] = useState(null);

	const sidebarItems = [
		{
			label: 'Главная',
			link: '/',
			icon: <HomeIcon />,
			iconactive: <HomeIcon fill={'#4680FF'} />,
		},
		{
			label: 'Информация о пересечениях (по гражданам)',
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: 'Граждане зарубежом',
					link: '/statistics/0',
				},
				{
					label: 'Граждане зарубежом (более 90 дней)',
					link: '/statistics/1',
				},
				{
					label: 'Вернувшиеся граждане',
					link: '/statistics/2',
				},
				{
					label: 'Вернувшиеся граждане (побывшие зарубежом более 90 дней)',
					link: '/statistics/3',
				},
			],
		},
		{
			label: 'Информации о пересечений границы',
			link: '#',
			icon: <DocIcon />,
			iconactive: <DocIcon fill={'#4680FF'} />,
			childs: [
				{
					label: 'Граждане за рубежом',
					link: '/bordercrossing/citizens-abroad',
				},
				{
					label: 'Граждане за рубежом (более 90 дней)',
					link: '/bordercrossing/citizens-abroaddays',
				},
				{
					label: 'Вернувшиеся граждане',
					link: '/bordercrossing/returned-citizens',
				},
				// {
				// 	label: 'Иностранные граждане в стране',
				// 	link: '/bordercrossing/citizens-country',
				// },
			],
		},
		// {
		// 	label: 'Иммиграция',
		// 	link: '#',
		// 	icon: <DocIcon />,
		// 	iconactive: <DocIcon fill={'#4680FF'} />,
		// 	childs: [
		// 		{
		// 			label: 'Кетганлар',
		// 			link: '/immigration/to-leave',
		// 		},
		// 		{
		// 			label: 'Келганлар',
		// 			link: '/immigration/to-come',
		// 		},
		// 	],
		// },
		// {
		// 	label: 'Эмиргация',
		// 	link: '#',
		// 	icon: <DocIcon />,
		// 	iconactive: <DocIcon fill={'#4680FF'} />,
		// 	childs: [
		// 		{
		// 			label: 'Чиққанлар',
		// 			link: '/emigration/to-leave',
		// 		},
		// 		{
		// 			label: 'Кирганлар',
		// 			link: '/emigration/to-come',
		// 		},
		// 	],
		// },
		{
			label: 'Отчёты',
			link: '#',
			icon: <DocNormal />,
			iconactive: <DocNormal fill={'#4680FF'} />,
			childs: [
				{
					label: 'Несовершеннолетние мигранты',
					link: '/child11',
				},
				{
					label: 'Граждане, родившиеся за рубежом',
					link: '/child22',
				},
				{
					label: 'Опекуны-правонарушители',
					link: '/child33',
				},
				{
					label: 'Задержанные граждане',
					link: '/child44',
				},
				{
					label: 'Граждане, погибшие за рубежом',
					link: '/child55',
				},
				{
					label: 'Лица, отказавшиеся от гражданства',
					link: '/child66',
				},
			],
		},
		{
			label: 'Заявки мигрантов',
			link: '#',
			icon: <Message />,
			iconactive: <Message fill={'#4680FF'} />,
			childs: [
				{
					label: 'Несовершеннолетние мигранты',
					link: '/child11',
				},
				{
					label: 'Граждане, родившиеся за рубежом',
					link: '/child22',
				},
				{
					label: 'Опекуны-правонарушители',
					link: '/child33',
				},
				{
					label: 'Задержанные граждане',
					link: '/child44',
				},
				{
					label: 'Граждане, погибшие за рубежом',
					link: '/child55',
				},
				{
					label: 'Лица, отказавшиеся от гражданства',
					link: '/child66',
				},
			],
		},
		{
			label: 'Список инспекторов',
			link: '#',
			icon: <ProfileUserIcon />,
			iconactive: <ProfileUserIcon fill={'#4680FF'} />,
			childs: [
				{
					label: 'Несовершеннолетние мигранты',
					link: '/child11',
				},
				{
					label: 'Граждане, родившиеся за рубежом',
					link: '/child22',
				},
				{
					label: 'Опекуны-правонарушители',
					link: '/child33',
				},
				{
					label: 'Задержанные граждане',
					link: '/child44',
				},
				{
					label: 'Граждане, погибшие за рубежом',
					link: '/child55',
				},
				{
					label: 'Лица, отказавшиеся от гражданства',
					link: '/child66',
				},
			],
		},
		{
			label: 'Доступы',
			link: '#',
			icon: <SecuritySafeIcon />,
			iconactive: <SecuritySafeIcon fill={'#4680FF'} />,
			childs: [
				{
					label: 'Список организаций',
					link: '/accesses/organizations',
				},
				{
					label: 'Список сотрудников',
					link: '/accesses/employees',
				},
			],
		},
	];

	const variants = {
		open: { width: 317, opacity: 1 },
		closed: { width: 65, opacity: 1 },
	};

	const toggleSection = section => {
		setOpenSection(prev => (prev === section ? null : section));
	};

	useEffect(() => {
		const path = window.location.pathname;
		const sectionIndex = sidebarItems.findIndex(item => item?.childs?.some(child => child.link === path));
		if (sectionIndex !== -1) {
			setOpenSection(sectionIndex);
		}
	}, [window.location.pathname]);

	const logOut = () => {
		removeToken();
		dispatch({ type: 'SET_USER', payload: {} });
		navigate('/login');
	};
	return (
		<>
			<motion.div
				initial={response ? 'closed' : 'open'}
				animate={response ? 'closed' : 'open'}
				variants={variants}
				className={`  bg-white custom-scrollbar  scrol sticky inset-x-0 top-0 p-[10px]  overflow-x-scroll ${
					response ? 'md:p-[10px]' : 'md:p-[20px]'
				} flex flex-col justify-between h-screen border-r border-r-gallery`}
			>
				<div className='  flex flex-col gap-4'>
					{response ? (
						<img src='/images/logo-response.svg' alt='logo' className='h-[45px] max-h-[45px] w-fit' />
					) : (
						<img src='/images/logo.svg' alt='logo' className='h-[45px] max-h-[45px] w-fit' />
					)}
					<div className='flex flex-col gap-2 mb-4'>
						{sidebarItems.map((item, index) => (
							<React.Fragment key={index}>
								{item?.link === '#' ? (
									<div>
										<div
											onClick={() => toggleSection(index)}
											key={index}
											to={item.link}
											className={`cursor-pointer  hover:bg-wilfSand rounded-lg ${openSection === index ? 'bg-[#EDF3FF]' : ''}   ${
												response ? ' py-3 flex justify-center' : 'p-4'
											}`}
										>
											<div className={`flex items-center justify-between gap-2 `}>
												<div className='flex justify-center items-center gap-2'>
													<div>{openSection === index ? item.iconactive : item.icon}</div>
													<p
														className={`text-base ${openSection === index ? 'text-royalBlue' : 'text-shuttleGray'}  ${
															response ? 'hidden' : 'block'
														}`}
													>
														{item.label}
													</p>
												</div>
												{openSection === index ? (
													<ArrowRight
														fill={'#4680FF'}
														className={`rotate-90 duration-250 transition-all ease-linear min-w-[18px] max-w-[18px] ${
															response ? 'hidden' : 'inline-block'
														}`}
													/>
												) : (
													<ArrowRight
														className={`rotate-270 duration-250 transition-all ease-linear min-w-[18px] max-w-[18px] ${
															response ? 'hidden' : 'inline-block'
														}`}
													/>
												)}
											</div>
										</div>
										{/* transition-max-height  duration-300 */}
										<div
											className={`overflow-hidden  rounded-md px-2 ease-in-out  ${openSection === index ? 'max-h-max ' : 'max-h-0'}  ${
												response
													? `!fixed z-[999]  ml-[60px] !h-[215px]  border-[#EFEFEF]   bg-[#fff]   text-white   overflow-y-auto bg-blue dark:bg-blueBack ${
															item?.type === 'top' ? 'mt-[-212px]' : 'mt-[-56px]'
													  }`
													: 'bg-white'
											}`}
										>
											<div className='w-full flex flex-col '>
												{item?.childs?.map((ch, ch_index) => {
													return (
														<div className=' w-full flex flex-col gap-[10px] mt-[10px] ' key={ch_index}>
															<NavLink to={ch.link} className={'flex  items-center pl-[5px] gap-[10px]  justify-start'}>
																{response ? null : (
																	<div className='max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center'>
																		<div className='bg-[#B9BEC7] rounded-full w-1 h-1'></div>
																	</div>
																)}
																<span className={` select-none ${response ? '' : ''}`}>{ch?.label}</span>
															</NavLink>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								) : (
									<NavLink
										key={index}
										to={item.link}
										className={({ isActive }) =>
											isActive
												? `cursor-pointer  hover:bg-wilfSand rounded-lg flex items-center gap-2 !bg-zumthor ${
														response ? ' py-2 flex justify-center' : 'p-4'
												  }`
												: `cursor-pointer p-4 hover:bg-wilfSand rounded-lg flex items-center gap-2  ${response ? ' py-2 flex justify-center' : 'p-4'}`
										}
										onClick={() => {
											setOpenSection(null);
										}}
									>
										<div>{location.pathname === item.link ? item.iconactive : item.icon}</div>
										<p className={`text-base text-shuttleGray ${response ? 'hidden' : 'block'}`}>{item.label}</p>
									</NavLink>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
				<div
					className={`bottom-0 bg-white w-full flex cursor-pointer  hover:bg-wilfSand rounded-lg items-center gap-2 ${response ? ' py-2 flex justify-center' : 'p-4'}`}
					onClick={logOut}
				>
					<img src='/images/logout.svg' alt='logout' />
					<div className={`text-[#5B6B79] text-[16px] font-normal leading-[20px] ${response ? 'hidden' : 'block'}`}>Выйти</div>
				</div>
			</motion.div>
		</>
	);
};

export default SideBar;
