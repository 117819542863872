import React from 'react';

const Notification = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.1153 12.075L15.282 10.6917C15.107 10.3834 14.9487 9.80002 14.9487 9.45835V7.35002C14.9487 5.39169 13.7987 3.70002 12.1403 2.90835C11.707 2.14169 10.907 1.66669 9.99032 1.66669C9.08198 1.66669 8.26532 2.15835 7.83198 2.93335C6.20698 3.74169 5.08198 5.41669 5.08198 7.35002V9.45835C5.08198 9.80002 4.92365 10.3834 4.74865 10.6834L3.90698 12.075C3.57365 12.6334 3.49865 13.25 3.70698 13.8167C3.90698 14.375 4.38198 14.8084 4.99865 15.0167C6.61532 15.5667 8.31532 15.8334 10.0153 15.8334C11.7153 15.8334 13.4153 15.5667 15.032 15.025C15.6153 14.8334 16.0653 14.3917 16.282 13.8167C16.4987 13.2417 16.4403 12.6084 16.1153 12.075Z'
				fill='#5B6B79'
			/>
			<path
				d='M12.3577 16.675C12.0077 17.6417 11.0827 18.3334 9.99941 18.3334C9.34108 18.3334 8.69108 18.0667 8.23275 17.5917C7.96608 17.3417 7.76608 17.0084 7.64941 16.6667C7.75775 16.6834 7.86608 16.6917 7.98275 16.7084C8.17441 16.7334 8.37441 16.7584 8.57441 16.775C9.04941 16.8167 9.53275 16.8417 10.0161 16.8417C10.4911 16.8417 10.9661 16.8167 11.4327 16.775C11.6077 16.7584 11.7827 16.75 11.9494 16.725C12.0827 16.7084 12.2161 16.6917 12.3577 16.675Z'
				fill='#5B6B79'
			/>
		</svg>
	);
};

export default Notification;
