import React from 'react';

const ProfileUserIcon = ({ fill }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
			<path
				opacity='0.4'
				d='M9 2.81055C6.38 2.81055 4.25 4.94055 4.25 7.56055C4.25 10.1305 6.26 12.2105 8.88 12.3005C8.96 12.2905 9.04 12.2905 9.1 12.3005C9.12 12.3005 9.13 12.3005 9.15 12.3005C9.16 12.3005 9.16 12.3005 9.17 12.3005C11.73 12.2105 13.74 10.1305 13.75 7.56055C13.75 4.94055 11.62 2.81055 9 2.81055Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M14.08 14.9604C11.29 13.1004 6.73996 13.1004 3.92996 14.9604C2.65996 15.8104 1.95996 16.9604 1.95996 18.1904C1.95996 19.4204 2.65996 20.5604 3.91996 21.4004C5.31996 22.3404 7.15996 22.8104 8.99996 22.8104C10.84 22.8104 12.68 22.3404 14.08 21.4004C15.34 20.5504 16.04 19.4104 16.04 18.1704C16.03 16.9404 15.34 15.8004 14.08 14.9604Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				opacity='0.4'
				d='M19.99 8.15065C20.15 10.0906 18.77 11.7906 16.86 12.0206C16.85 12.0206 16.85 12.0206 16.84 12.0206H16.81C16.75 12.0206 16.69 12.0206 16.64 12.0406C15.67 12.0906 14.78 11.7806 14.11 11.2106C15.14 10.2906 15.73 8.91065 15.61 7.41065C15.54 6.60065 15.26 5.86065 14.84 5.23065C15.22 5.04065 15.66 4.92065 16.11 4.88065C18.07 4.71065 19.82 6.17065 19.99 8.15065Z'
				fill={fill ? fill : '#5B6B79'}
			/>
			<path
				d='M21.99 17.4009C21.91 18.3709 21.29 19.2109 20.25 19.7809C19.25 20.3309 17.99 20.5909 16.74 20.5609C17.46 19.9109 17.88 19.1009 17.96 18.2409C18.06 17.0009 17.47 15.8109 16.29 14.8609C15.62 14.3309 14.84 13.9109 13.99 13.6009C16.2 12.9609 18.98 13.3909 20.69 14.7709C21.61 15.5109 22.08 16.4409 21.99 17.4009Z'
				fill={fill ? fill : '#5B6B79'}
			/>
		</svg>
	);
};

export default ProfileUserIcon;
