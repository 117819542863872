import React from 'react';

const ArrowRight = props => {
	return (
		<svg {...props} xmlns='http://www.w3.org/2000/svg' width={props?.width || '18'} height={props?.height || '18'} viewBox='0 0 18 18' fill='none'>
			<path
				d='M6.68251 15.5024C6.54001 15.5024 6.39751 15.4499 6.28501 15.3374C6.06751 15.1199 6.06751 14.7599 6.28501 14.5424L11.175 9.65239C11.535 9.29239 11.535 8.70739 11.175 8.34739L6.28501 3.45739C6.06751 3.23989 6.06751 2.87989 6.28501 2.66239C6.50251 2.44489 6.86251 2.44489 7.08001 2.66239L11.97 7.55239C12.3525 7.93489 12.57 8.45239 12.57 8.99989C12.57 9.54739 12.36 10.0649 11.97 10.4474L7.08001 15.3374C6.96751 15.4424 6.82501 15.5024 6.68251 15.5024Z'
				fill={props?.fill ? props?.fill : '#5B6B79'}
			/>
		</svg>
	);
};

export default ArrowRight;
