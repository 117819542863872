import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import { useMemo } from "react";
import PageProvider from "./PageProvider";

const generateRouter = (route) => {
  for (const item of route) {
    const PageComponent = item.component;
    if (PageComponent) {
      item.element = (
        <PageProvider handle={item.handle}>
          <PageComponent handle={item.handle} />
        </PageProvider>
      );
      delete item.component;
    }
    if (item.children != null) generateRouter(item.children);
  }
};

const RouteProvider = () => {
  const main_routes = useMemo(() => {
    generateRouter(routes);
    return routes;
  }, []);

  const router = useMemo(() => createBrowserRouter(main_routes), [main_routes]);

  return <RouterProvider router={router} />;
};

export default RouteProvider;
