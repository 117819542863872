import React from "react";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./i18n";
import LoadingMain from "../sections/utilsSections/LoadingMain";

const WebProvider = ({ children }) => {
  //   const { i18n } = useTranslation();
  const loading = useSelector((s) => s.loading);
  return (
    <>
      {/* <div i18n={i18n}> */}
      <div>
        {children}
        {loading && <LoadingMain />}
      </div>
    </>
  );
};
export default WebProvider;
