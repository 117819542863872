import { Dropdown } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuIcon from "../../assets/svg/MenuIcon";
import Notification from "../../assets/svg/Notification";
import Switcher from "../../utils/Switcher";
const Header = ({ setResponse, response = false }) => {
  const { i18n, t } = useTranslation();
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div
      style={{
        width: `calc(${response ? "100% - 65px" : "100% - 317px"})`,
      }}
      className={`px-[20px] fixed py-3 h-[73px] mx-auto border-b bg-white border-b-gallery duration-250 transition-all ease-linear z-999 
	${response ? "max-w-[1935px]" : "max-w-[1683px]"}
	flex justify-between `}
    >
      <div className="flex gap-[15px]">
        <button
          onClick={() => {
            setResponse(!response);
          }}
          className=" cursor-pointer p-[10px] rounded-lg bg-alabaster"
        >
          <MenuIcon />
        </button>
        <form className=" border-[#FFFFFF] border-[1px]  hover:border-[1px] hover:border-[#EFEFEF] cursor-pointer rounded-lg ">
          <input
            type="text"
            className="py-[10px] pr-[10px] pl-[40px] w-full  border-1 focus:border-[1px] hover:border-[1px] border-[#FFFF] hover:border-[] cursor-pointer focus:border-[#EFEFEF] rounded-lg  "
            style={{
              background: "url(/images/search.svg)",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "center",
              backgroundPositionX: "10px",
            }}
          />
        </form>
      </div>
      <div className="flex gap-[10px]">
        <Dropdown
          className="p-[10px] z-20 bg-alabaster outline-none"
          arrowIcon={false}
          renderTrigger={() => (
            <div className="cursor-pointer  p-[10px] rounded-lg bg-alabaster flex justify-center items-center h-[44px] w-[44px]">
              {i18n.language === "ru" ? (
                <img src="/images/rus.svg" alt="" />
              ) : (
                <img src="/images/uzb.svg" alt="" />
              )}
            </div>
          )}
        >
          <Dropdown.Item
            className="px-0"
            onClick={() => handleLanguageChange("uz")}
          >
            <img className="w-6 h-4" src="/images/uzb.svg" alt="" />
          </Dropdown.Item>
          <Dropdown.Item
            className="mt-2 px-0"
            onClick={() => handleLanguageChange("ru")}
          >
            <img src="/images/rus.svg" alt="" />
          </Dropdown.Item>
        </Dropdown>
        <button className="cursor-pointer p-[10px] rounded-lg bg-alabaster  dark:bg-shuttleGray">
          {/* <SunIcon /> */}
          <Switcher />
        </button>
        <div className="cursor-pointer relative  w-[44px] h-[44px] flex  justify-center items-center">
          <div className="flex justify-center items-center bg-royalBlue text-white rounded-full absolute  text-[12px] top-[6px] right-[6px] w-4 h-4">
            9
          </div>
          <Notification />
        </div>
        <button className="w-[44px] h-[44px] rounded-full bg-[#E9F0FF] text-royalBlue">
          U
        </button>
      </div>
    </div>
  );
};

export default Header;
